

$amGray900: #2d3449;
$amGray800: #3f465a;
$amGray700: #51586e;
$amGray600: #626a82;
$amGray500: #78819b;
$amGray400: #979cb2;
$amGray300: #bbc0d3;
$amGray200: #d0d3e4;
$amGray100: #e0e3f1;
$amGray50: #f2f4fb;
$amGray25: #f8f9fe;
$amGray10: #fafafa;

$amOrange25: #FEF3E3;
$amOrange100: #FCE1B9;
$amOrange200: #FACD8A;
$amOrange300: #F8B95B;
$amOrange400: #F7AA37;
$amOrange500: #F59B14;
$amOrange600: #F49312;
$amOrange700: #F2890E;
$amOrange800: #F07F0B;
$amOrange900: #EE6D06;

$amBlue50: #e0eff8;
$amBlue100: #b3d7ed;
$amBlue200: #80bce1;
$amBlue300: #4da1d4;
$amBlue400: #268dcb;
$amBlue500: #0079c2;
$amBlue600: #0071bc;
$amBlue700: #0066b4;
$amBlue800: #005cac;
$amBlue900: #00499f;

$amAuxRed: #e85656;
$amAuxOrange: #febf3c;
$amAuxYellow: #ffee4e;
$amAuxGreen: #59c50a;
$amBlack: black;
$amWhite: white;
$amLightBlue: #E8F3FA;
$amOffWhite: #E7E7E7;
$amLightGrey: #E0E0E0;