.mat-sparkler {
	background-color: #ffee93 !important;
	color: black !important;
}

.mat-mint-green {
	background-color: #adf7b6 !important;
	color: black !important;
}

.mat-gray {
	background-color: #e0e0e0 !important;
	color: black !important;
}

@keyframes glowing {
	0% {
		background-color: #ffffdb;
	}
	20% {
		background-color: #ffe44d;
	}
	100% {
		background-color: #ffffdb;
	}
}
.mat-focus {
	animation: glowing 2000ms infinite !important;
}

.mat-bid-no-bidder {
	background-color: white;
}

.mat-bid-owned {
	animation: bid-owned 2000ms;
	animation-fill-mode: forwards !important;
}

@keyframes bid-owned {
	0% {
		background-color: #1b9b02;
	}
	100% {
		background-color: #e3ffdb;
	}
}

.mat-bid-lost {
	animation: bid-lost 2000ms;
	animation-fill-mode: forwards;
}

@keyframes bid-lost {
	0% {
		background-color: #a10000;
	}
	100% {
		/*background-color: #ffdbdb;*/
		background-color: white;
	}
}
