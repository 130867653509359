@import './theme.scss';
@import './manager-colors.scss';
@import 'am-colors';

html {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  height: calc(100% - calc(env(safe-area-inset-top) + env(safe-area-inset-bottom)));
  margin: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
}

.mdc-evolution-chip__checkmark {
  display: none;
}

.mat-mdc-standard-chip .mdc-evolution-chip__graphic {
  width: 0 !important;
}

.division-mobile .mdc-data-table__row{
  height: 20px !important;
}

.division-mobile .mdc-data-table__header-row{
  height: 20px !important;
}

.other-player-wallets-mobile  .mdc-evolution-chip-set__chips{
  display: flex;
  flex-flow: nowrap;
  margin: 0px !important;
}

.other-player-wallets-mobile .mdc-evolution-chip-set{
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  overflow-x: none;
  scrollbar-width: 5px;
}

.division .mdc-data-table__row{
  height: 40px !important;
}

.division .mdc-data-table__header-row{
  height: 40px !important;
}

.bid-controls-mobile .mat-mdc-card {
  box-shadow: none;
  --mdc-elevated-card-container-color: none;
}

.profile-section {
  display: flex;
  flex-direction: column;

  .display-text {
    text-align: center;
    padding-top: 8px;
  }

  .email {
    font-size: small;
    font-style: italic;
  }

  img, .default-icon {
    margin: 16px auto 0 auto;
  }
}

.scrollbar-y {
  overflow-y: auto;
}

.scrollbar-y::-webkit-scrollbar {
  width: 5px;
}

.scrollbar-y::-webkit-scrollbar-thumb {
  background: $amOffWhite;
}
